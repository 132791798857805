import { useEffect, useState } from 'react';

import { get, makeUrl, post } from 'apiutils';

import { LogoutState } from '../../types/LogoutState';

export const useLogout = (logoutId?: string) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loggedOut, setLoggedOut] = useState<boolean>(false);
    const [postLogoutRedirectUri, setPostLogoutRedirectUri] = useState<string>();

    const url = makeUrl('Account', 'Logout');
    const getLogoutState = async () => {
        try {
            const { body } = await get(url, { logoutId: logoutId });
            if (!body) return;

            const logoutState = body as LogoutState;
            if (logoutState.loggedOut) {
                setPostLogoutRedirectUri(logoutState.postLogoutRedirectUri);
                setLoggedOut(true);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLogoutState();
    }, []);

    const onLogout = async () => {
        try {
            setLoading(true);
            const { body } = await post(makeUrl('Account', 'Logout'));
            if (!body) return;

            const logoutState = body as LogoutState;
            setPostLogoutRedirectUri(logoutState.postLogoutRedirectUri);
            setLoggedOut(true);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return [loading, loggedOut, onLogout, postLogoutRedirectUri] as const;
};
